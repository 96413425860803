import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { IndexComponent } from './index/index.component';
import { AuthGuard } from '../_guards';

export const routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'index', component: IndexComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '**', redirectTo: 'index', pathMatch: 'full' },
      {
        path: 'super',
        loadChildren: './superadmin/superadmin.module#SuperadminModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'administrate',
        loadChildren: './administrate/administrate.module#AdministrateModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'import',
        loadChildren: './import/import.module#ImportModule'
      },
      {
        path: 'report',
        loadChildren: './report/report.module#ReportModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'videos',
        loadChildren: './videos/videos.module#VideosModule',
      },
      {
        path: 'query',
        loadChildren: './query/query.module#QueryModule',
      },
      {
        path: 'billing',
        loadChildren: './billing/billing.module#BillingModule',
        canActivate: [AuthGuard]
      },
      
      {
        path: 'product',
        loadChildren: './product/product.module#ProductModule',
      },
      {
        path: 'home',
        loadChildren: 'app/routes/home/home.module#HomeModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'payment',
        loadChildren: './payment/payment.module#PaymentModule'
      },
      {
        path: 'students',
        loadChildren: './students/students.module#StudentsModule'
      },
      {
        path: 'qualification',
        loadChildren: './qualification/qualification.module#QualificationModule'
      },
      {
        path: 'error',
        loadChildren: './error/error.module#ErrorModule'
      },
      {
        path: 'admin-apps',
        loadChildren: './admin-apps/admin-apps.module#AdminAppsModule'
      },
    ]
  },

  // Not lazy-loaded routes
  { path: 'school', loadChildren: './school/school.module#SchoolModule' },
  { path: 'consultbill', loadChildren: './consultbill/consultbill.module#ConsultbillModule' },
  { path: 'mapa', loadChildren: './mapa/mapa.module#MapaModule' },
  { path: 'mapa-route', loadChildren: './mapa-route/mapa-route.module#MapaRouteModule' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'lock', component: LockComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '404', component: Error404Component },
  { path: '500', component: Error500Component },

  // Not found
  { path: '**', redirectTo: 'index' }
];
