import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {UserLogin} from '../../routes/administrate/class/class.userLogin';
import {Observable, throwError} from 'rxjs';
import {User} from '../../routes/administrate/class/class.user';
import { AngularFirestore } from 'angularfire2/firestore';
import { TokenService } from '../../models/services/token.service';
import {UserLoginOauth} from '../../routes/administrate/class/user.oauth';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class LoginService {
  urlgetToken = environment.oauthServer + "/oauth/oauth/token";
  // urlService = environment.oauthServer + "/adminService/authentication/userLogin";
  urlService = environment.urlServer + "authentication/userLogin";
  urlTest = environment.urlServer + "authentication/test";
  city = '';
  private loggedUser;

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private tokenService: TokenService
  ) {}

  userLoginJWT(usrLogin: UserLogin): Observable<User> {
    console.log(this.urlService,"\n", usrLogin);
    return this.http
        .post<User>(this.urlService, usrLogin);
  }
  userLogin(usrLogin: UserLogin): Observable<User> {
    const token = localStorage.getItem('authToken'); // O usa otro método de almacenamiento
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token ? token : ''}` // Agrega token o vacío si es nulo
    });
    return this.http.post<User>(this.urlService, usrLogin, { headers })
        .pipe(catchError(this.handleError));
  }

  // userLoginJWT(usrLogin: UserLoginOauth): Observable<Object> {
  //   // Codifica el clientId y clientSecret en Base64 (ejemplo: 'myClientId:myClientSecret')
  //   const clientId = 'weconnectPlatform'; // Reemplaza con tu clientId
  //   const clientSecret = 'weconnect.123'; // Reemplaza con tu clientSecret
  //   const base64Credentials = btoa(`${clientId}:${clientSecret}`);
  //
  //   // Crea HttpParams para enviar los datos
  //   const body = new HttpParams()
  //       .set('username', usrLogin.username) // Asegúrate de que el nombre de la propiedad sea 'username'
  //       .set('password', usrLogin.password)
  //       .set('grant_type', usrLogin.grant_type);
  //
  //   // Establece las cabeceras, incluyendo Basic Auth
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     'Authorization': `Basic ${base64Credentials}` // Aquí agregas el Basic Auth
  //   });
  //
  //   return this.http.post<User>(this.urlgetToken, body.toString(), { headers })
  //       .pipe(
  //           catchError(this.handleError) // Manejamos los errores aquí
  //       );
  // }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Error del lado del servidor
      if (error.status === 400 && error.error.error === 'invalid_grant') {
        errorMessage = 'Credenciales incorrectas, por favor verifica tu email y contraseña.';
      } else {
        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
      }
    }
    return throwError(() => new Error(errorMessage));
  }
  get_test(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.urlTest, { observe: "response" });
  }

  saveLoginRecord(date: Date, ip, location, device, type:string) {
    
    this.loggedUser = this.tokenService.USER;
    this.city = this.loggedUser.selectedCompany.city.name;
    const companyName = this.loggedUser.selectedCompany.companyName;
    const idCompany = this.loggedUser.selectedCompany.id;
    // date.toLocaleString
    // date.toLocaleDateString
    // date.toLocaleTimeString
    const collectionBitacora = this.firestore
      .collection(this.city)
      .doc("Bitacora")
      .collection('fechas');
      // .doc(date.getTime().toString());
    const data = {
      idcompany: idCompany,
      namecompany: this.loggedUser.selectedCompany.companyName,
      date: date,
      ip: ip,
      user: location,
      device: device,
      type: type
    };
    collectionBitacora
      .add(data)
      .then((docRef) => {
        console.log("Registro guardado");
      })
      .catch((error) => {
        console.error("Error en login:", error);
      });
  }
}
