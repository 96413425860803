import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, QueryFn } from 'angularfire2/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpResponse, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { User } from '../../routes/administrate/class/class.user';
import { TokenService } from '../../models/services/token.service';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SendnotificationService {

  urlService = environment.urlServer;
  private user;
  private city;
  private namecompany;
  private idcompany;

  constructor(
    private db: AngularFirestore,
    private authdirebase: AngularFireAuth,
    private http: HttpClient,
    private token: TokenService
  ) {

    this.user = this.token.USER;
    this.city = this.user.selectedCompany.city.name;
    this.namecompany = this.user.selectedCompany.companyName;
    this.idcompany = this.user.selectedCompany.id

  }

  get_data_invoice_by_idcompany(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/listaEstudiantes/" + this.user.selectedCompany.id,
        { observe: "response" }
      )
  }

  get_data_drivers_by_idcompany(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getDriverNotificationList/" + this.user.selectedCompany.id,
        { observe: "response" }
      )
  }

  get_data_routes_by_idcompany(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getPassengerAdvisorRouteList/" + this.user.selectedCompany.id,
        { observe: "response" }
      )
  }

  get_data_level(
    idcompany: string
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getParallelByCompanyId/" + idcompany,
        { observe: "response" }
      );
  }

  get_data_product(
    idcompany: string,
    idlevel: any,
    idroute: any
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getCarTypesByCOmpanyIdandLevelRouteId/" + idcompany + "/" + idlevel + "/" + idroute,
        { observe: "response" }
      );
  }

  get_data_invoice_with_filters(
    idcompany: string,
    idlevel: any,
    idroute: any,
    idtypeproduct: any
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getNotificationList/" + idcompany + "/" + idlevel + "/" + idroute + "/" + idtypeproduct,
        { observe: "response" }
      );
  }

  get_route(
    idcompany: number,
    idlevel: any
  ): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        this.urlService + "notificaciones/v1.0/getRoutesByCompanyIdandlevelID/" + idcompany + "/" + idlevel,
        { observe: "response" }
      );
  }

  add_data_novedades_invoice(
    data: any,
    campo: string
  ): Observable<any> {
    return new Observable((observer) => {

      this.db.collection(this.city.toString()).doc('novedades').collection('idCompany_' + this.idcompany).doc('representantes').collection(campo).add(data).then(
        (response) => {
          observer.next({
            estado: true
          });
        }
      ).catch((error) => {
        observer.next({
          estado: false
        });

      })

    }
    )

  }

  add_data_novedades_drivers(
    data: any,
    campo: string
  ): Observable<any> {
    return new Observable((observer) => {

      this.db.collection(this.city.toString()).
        doc('novedades').
        collection('idCompany_' + this.idcompany).
        doc('conductor').collection(campo).
        add(data).then(
          (response) => {
            observer.next({
              estado: true
            });
          }
        ).catch((error) => {
          observer.next({
            estado: false
          });

        })

    }
    )

  }

  getListInvoicesByCompany(nove: String) {
    return this.db
      .collectionGroup(this.city, ref => ref.where('novedades', '==', nove))
      .valueChanges();
  }


  getNotificationsSentRoute(date: string): Observable<Promise<firebase.firestore.QuerySnapshot>[]> {
    const year = Number(date.split("-")[2]);
    const month = Number(date.split("-")[1]) - 1; // Restamos 1 para ajustar el índice del mes
    const lastDay = new Date(year, month + 1, 0).getDate(); // Obtener el último día del mes actual
    let documentsArray: Promise<firebase.firestore.QuerySnapshot>[] = [];
    const idsDocumentsSaved: String[] = [];
    var subject = new Subject<Promise<firebase.firestore.QuerySnapshot>[]>();
  
    this.get_data_routes_by_idcompany().subscribe(
      (response) => {
        if (response.body.statusCode == 200) {
          const dataRoutes: any[] = response.body.result;
          dataRoutes.forEach((route) => {
            route.driverNotificationRouteDTOList.forEach((driver) => {
              for (let i = 1; i <= lastDay; i++) { // Cambiamos para empezar desde 1
                let dateConsult = `${i}`.padStart(2, '0') + `-${String(month + 1).padStart(2, '0')}-${year}`; // Usamos month + 1
                const collection = `${dateConsult}_conductor_${driver.idConductor}`;
  
                // Primera consulta a la colección 'idCompany_' + this.idcompany
                const document = this.db
                  .collection(this.city)
                  .doc('novedades')
                  .collection('idCompany_' + this.idcompany)
                  .doc('representantes')
                  .collection(collection)
                  .ref.where(
                    "envia",
                    "==",
                    `admin_${this.user.selectedCompany.id}`
                  )
                  .get();
  
                if (!idsDocumentsSaved.includes(collection)) {
                  idsDocumentsSaved.push(collection);
                  documentsArray.push(
                    document.then(snapshot => {
                      // Verificamos si la colección está vacía
                      if (snapshot.empty) {
                        // Si está vacía, consultar la otra colección
                        return this.db
                          .collection(this.city)
                          .doc('novedades')
                          .collection(this.namecompany)
                          .doc('representantes')
                          .collection(collection)
                          .ref.where(
                            "envia",
                            "==",
                            `admin_${this.user.selectedCompany.id}`
                          )
                          .get();
                      }
                      // Si no está vacía, simplemente devolver el snapshot original
                      return snapshot;
                    })
                  );
                }
              }
            });
          });
          subject.next(documentsArray);
        }
      },
      (error) => { }
    );
    return subject.asObservable();
  }
  


  getNotificationsSentAdvisor(date: string) {
    const year = Number(date.split("-")[2]);
    const month = Number(date.split("-")[1]) - 1; // Restamos 1 para ajustar el índice del mes
    const lastDay = new Date(year, month + 1, 0).getDate(); // Obtener el último día del mes actual
    let documentsArray: Promise<firebase.firestore.QuerySnapshot>[] = [];

    for (let i = 1; i <= lastDay; i++) { // Cambiamos para empezar desde 1
      let dateConsult = `${i}`.padStart(2, '0') + `-${String(month + 1).padStart(2, '0')}-${year}`; // Usamos month + 1

      // Primero, intentamos obtener documentos de la colección 'idCompany_' + this.idcompany
      const document = this.db
        .collection(this.city)
        .doc('novedades')
        .collection('idCompany_' + this.idcompany)
        .doc('representantes')
        .collection(`${dateConsult}_conductor_${this.user.selectedCompany.id}`)
        .ref.orderBy("fecha", "asc")
        .get();

      documentsArray.push(document.then(snapshot => {
        // Verificamos si la colección está vacía
        if (snapshot.empty) {
          // Si está vacía, consultar la otra colección
          return this.db
            .collection(this.city)
            .doc('novedades')
            .collection(this.namecompany)
            .doc('representantes')
            .collection(`${dateConsult}_conductor_${this.user.selectedCompany.id}`)
            .ref.orderBy("fecha", "asc")
            .get();
        }
        // Si no está vacía, simplemente devolver el snapshot original
        return snapshot;
      }));
    }
    return documentsArray;
  }


  getNotificationsSentDriver(date: string) {
    const year = Number(date.split("-")[2]);
    const month = Number(date.split("-")[1]) - 1; // Aquí restamos 1 para que sea 0-11
    const lastDay = new Date(year, month + 1, 0).getDate(); // El último día del mes actual
    let documentsArray: Promise<firebase.firestore.QuerySnapshot>[] = [];
  
    //console.log(`Consultando datos para Driver del mes: ${month + 1} año: ${year}`); // Log ajustado
  
    for (let i = 1; i <= lastDay; i++) {
      let dateConsult = `${i}`.padStart(2, '0') + `-${String(month + 1).padStart(2, '0')}-${year}`; // Usa month + 1 aquí
  
      // Primera consulta a la colección 'idCompany_' + this.idcompany
      const document = this.db
        .collection(this.city)
        .doc("novedades")
        .collection('idCompany_' + this.idcompany)
        .doc("conductor")
        .collection(`${dateConsult}`)
        .ref.orderBy("fecha", "asc")
        .get();
  
      documentsArray.push(
        document.then(snapshot => {
          // Verificamos si la colección está vacía
          if (snapshot.empty) {
            // Si está vacía, consultar la otra colección
            return this.db
              .collection(this.city)
              .doc("novedades")
              .collection(this.namecompany)
              .doc("conductor")
              .collection(`${dateConsult}`)
              .ref.orderBy("fecha", "asc")
              .get();
          }
          // Si no está vacía, simplemente devolver el snapshot original
          return snapshot;
        })
      );
    }
  
    // Verifica el tamaño de documentsArray y registra la información
    Promise.all(documentsArray).then((querySnapshots) => {
      querySnapshots.forEach((snapshot, index) => {
        //console.log(`Consulta para ${lastDay} de ${month + 1}-${year}: ${snapshot.empty ? 'Vacío' : 'Con Datos'}`); // Log ajustado
        if (!snapshot.empty) {
          snapshot.forEach(doc => {
            //console.log(`Documento encontrado: ${JSON.stringify(doc.data())}`);
          });
        }
      });
    });
  
    return documentsArray;
  }
}
