import { Injectable } from '@angular/core';
import { UserInformation} from '../../routes/pages/login/class/class.login';
import {result} from 'lodash';
const swal = require('sweetalert2');
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private _USER: UserInformation;
  private _TOKEN: string;

  constructor() { }

  public get USER(): UserInformation {
    if (this._USER != null) {
      // console.log('>>> ',this._USER.selectedCompany.city.name)
      return this._USER;
    } else if (this._USER == null && sessionStorage.getItem('userInformation') != null) {
      // console.log('is null', sessionStorage.getItem('userInformation'))
      this._USER = JSON.parse(sessionStorage.getItem('userInformation')) as UserInformation;
      return this._USER;
    }
    return new UserInformation();
  }

  public get TOKEN(): string {
    if (this._TOKEN != null) {
      return this._TOKEN;
    } else if (this._TOKEN == null && sessionStorage.getItem('token') != null) {
      this._TOKEN = sessionStorage.getItem('token');
      return this._TOKEN;
    }
    return null;
  }

  static getDataToken(ACCESS_TOKEN: string): any {
    if (ACCESS_TOKEN != null) {
      return JSON.parse(atob(ACCESS_TOKEN.split('.')[1]));
    } else {
      return null;
    }
  }

  public saveUserData = (ACCESS_TOKEN: string): void => {
    const payload = TokenService.getDataToken(ACCESS_TOKEN);
    this._USER = payload.userInformation;
    sessionStorage.setItem('userInformation', JSON.stringify(this._USER));
  }

  public saveUserToken = (ACCESS_TOKEN: string): void => {
    this._TOKEN = ACCESS_TOKEN;
    sessionStorage.setItem('token', this._TOKEN);
  }
  public isAuthenticated = (): boolean => {
    const payload = TokenService.getDataToken(this.TOKEN);
    return payload != null && payload.userAccountName && payload.userAccountName.length > 0;
  }

  public isLogged(): boolean {
    return sessionStorage.getItem('token') != null;

  }
  public clearStorage = (): void => {
    this._TOKEN = null;
    sessionStorage.clear();
  }

  public logOut = (): void => {
    this._TOKEN = null;
    this._USER = null;
    sessionStorage.clear();
  }
  public expiration(token: string) {
    const exp = JSON.parse(atob(token.split('.')[1]));
    const timestamp = (new Date().getTime() + 1) / 1000;
    const time = timestamp.toString().split('.');
    if (exp.exp < time[0]) {
      swal.fire ({
        title: 'Aviso',
        text: 'Sesión Expirada this service',
        icon: 'error'
      }).then((resulta) => {
        if (resulta.isConfirmed) {
          this.logOut();
          location.assign('login');
        }
      });
    }
  }

}
